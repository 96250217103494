import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, TitleStrategy } from '@angular/router';

import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import { MarkdownModule } from 'ngx-markdown';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { BearerTokenInterceptor, HttpErrorInterceptor } from 'framework';

import { AppInitService } from './app/services/app-init/app-init.service';
import { GlobalErrorHandler } from './app/services/app-init/global-error-handler';

import { ApiActivityInterceptor } from 'api-common';
import { ImpersonationInterceptor } from './app/services/interceptors/impersonation.interceptor';

import { routes, PageTitleStrategy } from './app/app-routes';
import { AuthConfigModule } from './app/auth/auth-config.module';

import { environment } from './environments/environment';

import { AppComponent } from './app/app.component';

if (environment.production) {
  enableProdMode();
}

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.initialize();
  };
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom([BrowserModule, BrowserAnimationsModule]),
    provideHttpClient(),
    importProvidersFrom(AuthConfigModule),
    importProvidersFrom(RouterModule.forRoot(routes)),
    importProvidersFrom(MarkdownModule.forRoot()),
    importProvidersFrom(Window),
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy
    },
    AppInitService,
    {
      provide: ErrorHandler,
      useClass: environment.applicationInsights.connectionString ? ApplicationinsightsAngularpluginErrorService : GlobalErrorHandler
    },
    DialogService,
    MessageService,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiActivityInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ImpersonationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ]
}).catch((err) => console.error(err));
